import React from 'react'
import Preloader from "../helper/Preloader";
import ColorInit from "../helper/ColorInit";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/FooterTwo";
import BottomFooter from "../components/BottomFooter";
import ShippingOne from "../components/ShippingOne";
import AboutusPage from "../components/Aboutus";
import ScrollToTop from "react-scroll-to-top";
import HeaderOne from "../components/HeaderOne";

const Aboutus = () => {
  return (
   <>
     {/* ColorInit */}
     <ColorInit color={true} />

{/* ScrollToTop */}
<ScrollToTop smooth color="#FA6400" />

{/* Preloader */}
<Preloader />

 {/* HeaderOne */}
 <HeaderOne />

{/* Breadcrumb */}
<Breadcrumb title={"About us"} />


<AboutusPage />

{/* ShippingOne */}
<ShippingOne />

{/* FooterTwo */}
<FooterTwo />

{/* BottomFooter */}
<BottomFooter />

   
   
   </>
  )
}

export default Aboutus