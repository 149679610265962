import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import HomePageOne from "./pages/HomePageOne";
import HomePageTwo from "./pages/HomePageTwo";
import HomePageThree from "./pages/HomePageThree";
import ShopPage from "./pages/ShopPage";
import ProductDetailsPageOne from "./pages/ProductDetailsPageOne";
import ProductDetailsPageTwo from "./pages/ProductDetailsPageTwo";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import AccountPage from "./pages/AccountPage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import ContactPage from "./pages/ContactPage";
import PhosphorIconInit from "./helper/PhosphorIconInit";
import VendorPage from "./pages/VendorPage";
import VendorDetailsPage from "./pages/VendorDetailsPage";
import VendorTwoPage from "./pages/VendorTwoPage";
import VendorTwoDetailsPage from "./pages/VendorTwoDetailsPage";
import BecomeSellerPage from "./pages/BecomeSellerPage";
import WishlistPage from "./pages/WishlistPage";
import "react-quill/dist/quill.snow.css";
import Aboutus from "./pages/Aboutus";
import MilitaryDefencePage from "./pages/MilitaryDefencePage";
import AerospacePage from "./pages/Aerospace";
import GovernmentPsuPage from "./pages/GovernmentPsuPage";

function App() {
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <PhosphorIconInit />
      <Routes>
        <Route exact path="/" element={<HomePageOne />} />
        <Route exact path="/index-two" element={<HomePageTwo />} />
        <Route exact path="/index-three" element={<HomePageThree />} />
        <Route exact path="/shop" element={<ShopPage />} />
        <Route
          exact
          path="/product-details"
          element={<ProductDetailsPageOne />}
        />
        <Route
          exact
          path="/product-details-two"
          element={<ProductDetailsPageTwo />}
        />
        <Route exact path="/cart" element={<CartPage />} />
        <Route exact path="/checkout" element={<CheckoutPage />} />
        <Route exact path="/about-us" element={<Aboutus />} />
        <Route exact path="/military-defence" element={<MilitaryDefencePage />} />
        <Route exact path="/aerospace" element={<AerospacePage />} />
        <Route exact path="/government-psu" element={<GovernmentPsuPage />} />
        <Route exact path="/account" element={<AccountPage />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route exact path="/blog-details" element={<BlogDetailsPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/vendor" element={<VendorPage />} />
        <Route exact path="/vendor-details" element={<VendorDetailsPage />} />
        <Route exact path="/vendor-two" element={<VendorTwoPage />} />
        <Route
          exact
          path="/vendor-two-details"
          element={<VendorTwoDetailsPage />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
