import React from "react";
import * as __ from "lodash";

const ProductDescription = ({ content }) => {
  return (
    <div className="mb-40">
      <h6 className="mb-24">
        {content?.name === "configue now" ? "" : "Product"}{" "}
        {__.capitalize(content?.name)}
      </h6>

      <div dangerouslySetInnerHTML={{ __html: content.data }} />
    </div>
  );
};

export default ProductDescription;
