export const Products = [
  {
    title: "RMC6440A",
    short_details: `<ul><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">4U high rackmount computer </strong></li><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">17.8" short-depth rackmount chassis</strong></li><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">14th/13th/10th Generation Intel processors </strong></li><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">Standard ATX motherboard</strong></li><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">7 x expansion slots</strong></li><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">8GB RAM (Up to 64GB) &amp; 250GB NVMe M.2 SSD</strong></li><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">1 x 3.5" external &amp; 3 x 5.25" external drive bays </strong></li><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">Front drive panel door with keylock </strong></li><li><strong class="ql-font-monospace" style="color: rgb(119, 119, 119);">400W ATX power supply, option for 500W or 650W ATX P/S</strong></li></ul>`,

    full_details: [
      {
        name: "description",
        selected: true,
        isActive: true,
        data: '<p><strong class="ql-font-monospace">The RMC6440A is a 4U rackmount computer designed for businesses and data centers. The rack mount computer supports a standard ATX motherboard with the latest Intel processors. It features 3 x 5.25" &amp; 1 x 3.5" external drive bays, 2 x front USB ports, and a drive panel door with a keylock. The RMC6440A is fully customizable and offers options including additional storage space, removable drive bay options, add-on cards, graphic cards, and Windows OS.</strong></p><p><br></p>',
      },
      {
        name: "specfications",
        selected: false,
        isActive: true,
        data: `<p><strong>Construction</strong></p><p>Heavy duty cold rolled electroplated steel</p><p><br></p><p><strong>Standard ATX M/B</strong></p><p>Size: 12” x 9.6” ATX motherboard</p><p>Processor:</p><p><br></p><p><a href="https://www.acnodes.com/rmc6440a-b.htm" rel="noopener noreferrer" target="_blank" style="color: rgb(84, 78, 78);">RMC6440A-B</a>:&nbsp;Intel 13/14th&nbsp;Generation Processor</p><p><a href="https://www.acnodes.com/rmc6440a-c.htm" rel="noopener noreferrer" target="_blank" style="color: rgb(84, 78, 78);">RMC6440A-C:</a>&nbsp;Intel 10th Generation Processor / Long-Life&nbsp;Cycle&nbsp;M/B&nbsp;(Through Q2 2030)</p><p>Memory: 4 x DIMM memory sockets (up to 64GB)</p><p>On-board LAN, Audio, Video</p><p>SATA Controller</p><p>USB ports</p><p><br></p><p><strong>Drive Capacity&nbsp;</strong></p><p>1 x 3.5” external&nbsp;drive bay</p><p>3 x 5.25” external&nbsp;drive bays</p><p><br></p><p><strong>Expansion Slot</strong></p><p>7 x expansion slots</p><p><br></p><p><strong>Front Panel Control</strong></p><p>Power ON/OFF button</p><p>System Reset button</p><p>2 x USB ports</p><p><br></p><p><strong>LED Indicator</strong></p><p>LED indicators for Power ON/OFF &amp; HDD Activity</p><p><br></p><p><strong>Security</strong></p><p>Drive panel door with keylock</p><p><br></p><p><strong>Air Filter</strong></p><p>Removable and washable air filter inside front panel</p><p><br></p><p><strong>Cooling Fan</strong></p><p>1 x 120mm (78cfm) ball bearing fan</p><p><br></p><p><strong>Power Supply</strong></p><p>400W ATX power supply</p><p>Option for 500W or 650W ATX power supply&nbsp;</p><p><br></p><p><strong>Color</strong></p><p>Black</p><p><br></p><p><strong>Weight</strong></p><p>40 lb / 18.14 kg</p><p><br></p><p><strong>Dimension</strong></p><p>19"(W) x 7"(H) x 17.8"(D)</p><p><br></p><p><strong>Environmental</strong></p><p>Operating&nbsp;Temperature: 0°C to 55°C</p><p>Humidity: 5~95%, non-condensing</p>`,
      },
      {
        name: "dimensions",
        selected: false,
        isActive: true,
        data: `<p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://www.acnodes.com/pub/media/wysiwyg/RMC6440-Dimension3.png" alt=""></p>`,
      },
      {
        name: "order info",
        selected: false,
        isActive: true,
        data: `<p><strong style="background-color: rgb(255, 255, 255); color: rgb(119, 119, 119);">RMC6440A-B:</strong></p><p><span style="background-color: rgb(255, 255, 255); color: rgb(119, 119, 119);">4U rackmount computer, Intel 13/14th&nbsp;Generation processors, standard ATX motherboard, 8GB memory,&nbsp;7 x expansion slots, 400W ATX power supply, 4U high 17.8” depth rack chassis, Black color.</span></p><p><br></p><p><strong style="background-color: rgb(255, 255, 255); color: rgb(119, 119, 119);">RMC6440A-C:</strong></p><p><span style="background-color: rgb(255, 255, 255); color: rgb(119, 119, 119);">4U rackmount computer, Intel 10th Generation processors, long-life cycle industrial ATX motherboard, 8GB DDR4&nbsp;memory,&nbsp;7 x expansion slots, 400W ATX power supply, 4U high 17.8” depth rack chassis, Black color.</span></p>`,
      },
      {
        name: "downloads",
        selected: false,
        isActive: true,
        data: 'The RMC6440A is a 4U rackmount computer designed for businesses and data centers. The rack mount computer supports a standard ATX motherboard with the latest Intel processors. It features 3 x 5.25" & 1 x 3.5" external drive bays, 2 x front USB ports, and a drive panel door with a keylock. The RMC6440A is fully customizable and offers options including additional storage space, removable drive bay options, add-on cards, graphic cards, and Windows OS.',
      },
      // {
      //   name: "configure now",
      //   selected: false,
      //   isActive: true,
      //   data: 'The RMC6440A is a 4U rackmount computer designed for businesses and data centers. The rack mount computer supports a standard ATX motherboard with the latest Intel processors. It features 3 x 5.25" & 1 x 3.5" external drive bays, 2 x front USB ports, and a drive panel door with a keylock. The RMC6440A is fully customizable and offers options including additional storage space, removable drive bay options, add-on cards, graphic cards, and Windows OS.',
      // },
    ],
  },
];
