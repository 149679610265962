import React from 'react'

const MilitaryDefence = () => {
  return (
    <>  
  <div className='container'>
    <h2> Military & Defence </h2>
    
    </div>  
    
    </>
  )
}

export default MilitaryDefence