import React from "react";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";

function ProductShortDesc({ content }) {
  const sanitizedContent = DOMPurify.sanitize(content);

  return <ReactQuill value={sanitizedContent} readOnly={true} theme="bubble" />;
}

export default ProductShortDesc;
