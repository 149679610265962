import React from 'react'

import Preloader from "../helper/Preloader";
import ColorInit from "../helper/ColorInit";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/FooterTwo";
import BottomFooter from "../components/BottomFooter";
import ShippingOne from "../components/ShippingOne";
import ScrollToTop from "react-scroll-to-top";
import HeaderOne from "../components/HeaderOne";
import MilitaryDefence from '../components/MilitaryDefence'
import GovernmentPsu from '../components/GovernmentPsu';

const GovernmentPsuPage = () => {
  return (
  <> 
   {/* ColorInit */}
   <ColorInit color={true} />

{/* ScrollToTop */}
<ScrollToTop smooth color="#FA6400" />

{/* Preloader */}
<Preloader />

 {/* HeaderOne */}
 <HeaderOne />

{/* Breadcrumb */}
<Breadcrumb title={"Government & PSU"} />


<GovernmentPsu />

{/* ShippingOne */}
<ShippingOne />

{/* FooterTwo */}
<FooterTwo />

{/* BottomFooter */}
<BottomFooter />


   </>
  )
}

export default GovernmentPsuPage